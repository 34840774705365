import Swal from "sweetalert2";

export const alertMixin = {
  data() {},
  methods: {
    alertHelp() {
      Swal.fire({
        title: this.$t("ALERT.ALERTHELPTITLE"),
        html: this.$t("ALERT.ALERTHELPDESCRIPTION"),
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },
    alertForm() {
      Swal.fire({
        title: this.$t("ALERT.ALERTFORMTITLE"),
        html: this.$t("ALERT.ALERTFORMDESCRIPTION"),
        icon: "warning",
        confirmButtonClass: "btn btn-warning",
      });
    },
    alertMeet() {
      Swal.fire({
        title: this.$t("ALERT.ALERTMEETTITLE"),
        html: this.$t("ALERT.ALERTMEETDESCRIPTION"),
        icon: "warning",
        confirmButtonClass: "btn btn-warning",
        customClass: "swal-wide",
      });
    },
    alertJamboard() {
      Swal.fire({
        title: this.$t("ALERT.ALERTJAMBOARDTITLE"),
        html: this.$t("ALERT.ALERTJAMBOARDDESCRIPTION"),
        icon: "warning",
        confirmButtonClass: "btn btn-warning",
        customClass: "swal-wide",
      });
    },
    alertEmailConfirm() {
      Swal.fire({
        // html: '<div><img src="/assets/media/bipart/logo-bipart-brand.svg" class="h-50px" alt="Logo" /></div><br />' +
        //     '<h5 class="text-primary">Non hai ancora confermato la verifica<br />della tua email [email utente]</h5>' +
        //     '<div>Confermala per entrare a far parte dell’organizzazione<br>e iscriverti ai processi partecipativi</div>' +
        //     '<div>Non l\'hai ancora ricevuta? <strong>Clicca il pulsante nella banda rossa per reinviarla</strong></div>',

        title: "",
        html: this.$t("ALERT.ALERTEMAILCONFIRM"),
        icon: "",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },
    alertEmailBlank() {
      Swal.fire({
        title: "",
        html: this.$t("ALERT.ALERTEMAILBLANK"),
        icon: "",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },
    alertEmailResendConfirm() {
      Swal.fire({
        title: "",
        html: this.$t("ALERT.ALERTEMAILRESENDCONFIRM"),
        icon: "",
        customClass: "swal-wide",
      });
    },
    alertUserRegisteredToOrganization() {
      Swal.fire({
        title: "",
        html: this.$t("ALERT.ALERTUSERREGISTEREDTOORGANIZATION"),
        icon: "",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },

    alertUserRegisteredToProcess() {
      Swal.fire({
        title: "",
        html: this.$t("ALERT.ALERTUSERREGISTEREDTOPROCESS"),
        icon: "",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },

    alertUserLoggedInAndRegisteredToProcess(value) {
      Swal.fire({
        title: "",
        html: this.$t("MESSAGEAUTH.2", { value: value }),
        icon: "",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },
    alertUserLoggedIn(value) {
      Swal.fire({
        title: "",
        html: this.$t("MESSAGEAUTH.3", { value: value }),
        icon: "",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },
    alertUserLoggedRegisteredToProcess(value) {
      Swal.fire({
        title: "",
        html: this.$t("MESSAGEAUTH.2", { value: value }),
        icon: "",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },

    alertEmailConfirmedOnRegisteredToOrganization(organization_name) {
      console.log("organization name: ", organization_name);
      Swal.fire({
        title: "",
        html:
          '<div><img src="/assets/media/bipart/logo-bipart-brand.svg" class="h-60px" alt="Logo" /></div><br />' +
          '<h5 class="text-primary">' +
          this.$t("ALERT.ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONTITLE") + "</h5>" +
          "<div>" + this.$t("ALERT.ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONDESCRIPTION") + "</div>",
        icon: "",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },

    alertEmailConfirmedOnRegisteredToProcess(organization_name, process_name) {
      Swal.fire({
        title: "",
        html: this.$t("MESSAGEAUTH.4", {
          organization_name: organization_name,
          process_name: process_name,
        }),
        icon: "",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },

    alertPermissionDenied() {
      Swal.fire({
        title: "",
        html: this.$t("ALERT.ALERTPERMISSIONDENIED"),
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },

    alertManageOwnerMailed(proponente) {
      Swal.fire({
        title: this.$t("IDEADETAIL.ALERTOWNERMAILEDTITLE"),
        html: `${this.$t("IDEADETAIL.ALERTOWNERMAILEDHTML1")} ${proponente} ${this.$t("IDEADETAIL.ALERTOWNERMAILEDHTML2")}`,
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },

    alertUserModuleAccess() {
      Swal.fire({
        title: "",
        // width: 700,
        html: this.$t("ALERT.ALERTUSERMODULENOTENABLED"), //"Utente non abilitato"
        icon: "info",
        // confirmButtonClass: "btn btn-primary",
        // confirmButtonText: 'Ho capito!',
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },

    alertCommentEmpty() {
      Swal.fire({
        title: "",
        html: this.$t("ALERT.ALERTCOMMENTEMPTY"), //Commento vuoto
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },

    alertCommentSended() {
      Swal.fire({
        title: "",
        html: this.$t("ALERT.ALERTCOMMENTSENDED"), //Commento inviato
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      }).then(() => {
        setTimeout(() => {
          window.scrollTo(
            0,
            document.body.scrollHeight || document.documentElement.scrollHeight,
            "smooth"
          );
        }, 500);
      });
    },

    alertCanNotFollow() {
      Swal.fire({
        title: this.$t("ALERT.ALERTCANNOTFOLLOW"),
        icon: "info",
        confirmButtonClass: "btn btn-secondary",
      }).then(() => {
        setTimeout(() => {
          let current_process = JSON.parse(
            window.localStorage.getItem("current_process_data")
          );
          current_process.is_active = false;
          window.localStorage.setItem(
            "current_process_data",
            JSON.stringify(current_process)
          );
          window.location.reload();
          return -1;
        }, 500);
      });
    },
    alertRemoveFollower(moduleContentType, isBandoZac = false) {
      Swal.fire({
        title:  isBandoZac ? "Non stai più seguendo questo esempio!" : this.$t(this.$handleContentTypeLabel(moduleContentType) + "DETAIL.ALERT7TITLE"),
        html: "",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
      });
    },
    alertAddFollower(moduleContentType, isBandoZac = false) {
      Swal.fire({
        title: isBandoZac ? "Stai seguendo questo esempio!" : this.$t(this.$handleContentTypeLabel(moduleContentType) + "DETAIL.ALERT6TITLE"),
        html: "",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
    },
    alertMobileConfirm() {
      Swal.fire({
        title: "",
        html: this.$t("ALERT.ALERTMOBILECONFIRM"),
        icon: "",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },
    alertEmailMobileConfirm() {
      Swal.fire({
        title: "",
        html: this.$t("ALERT.ALERTEMAILMOBILECONFIRM"),
        icon: "",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },

    //MATRIX LIGHT
    alertUserModuleAccessBitmaskMessage() {
      const current_process = window.localStorage.getItem("current_process_data");
      let guidelines = ""
      let process_id = 0;

      if( current_process != 'undefined' && current_process !== null ) {
        const cod = JSON.parse(current_process);
        guidelines = cod?.data?.guidelines || "";
        process_id = cod?.data?.id || 0;
      }
 
      Swal.fire({
        title: "",
        html: this.$t("ALERT.ALERTUSERMODULENOTENABLEDBITMASKMESSAGE", {exist_guidelines: guidelines !== "" ? this.$t("ALERT.ALERTUSERMODULENOTENABLEDBITMASK", {process_id: process_id}) :  ''}),
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },

    alertUserModuleAccessBitmaskComment() {
      const current_process = window.localStorage.getItem("current_process_data");
      let guidelines = ""
      let process_id = 0;

      if( current_process != 'undefined' && current_process !== null ) {
        const cod = JSON.parse(current_process);
        guidelines = cod?.data?.guidelines || "";
        process_id = cod?.data?.id || 0;
      }
 
      Swal.fire({
        title: "",
        html: this.$t("ALERT.ALERTUSERMODULENOTENABLEDBITMASKCOMMENT", {exist_guidelines: guidelines !== "" ? this.$t("ALERT.ALERTUSERMODULENOTENABLEDBITMASK", {process_id: process_id}) :  ''}),
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },

    alertUserModuleAccessBitmaskFollow() {
      const current_process = window.localStorage.getItem("current_process_data");
      let guidelines = ""
      let process_id = 0;

      if( current_process != 'undefined' && current_process !== null ) {
        const cod = JSON.parse(current_process);
        guidelines = cod?.data?.guidelines || "";
        process_id = cod?.data?.id || 0;
      }
 
      Swal.fire({
        title: "",
        html: this.$t("ALERT.ALERTUSERMODULENOTENABLEDBITMASKFOLLOW", {exist_guidelines: guidelines !== "" ? this.$t("ALERT.ALERTUSERMODULENOTENABLEDBITMASK", {process_id: process_id}) :  ''}),
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },

    alertUserModuleAccessBitmaskCreate(moduleContentType) {
      const current_process = window.localStorage.getItem("current_process_data");
      const type = moduleContentType?.code || "PROPOSAL";
      let guidelines = ""
      let process_id = 0;

      if( current_process != 'undefined' && current_process !== null ) {
        const cod = JSON.parse(current_process);
        guidelines = cod?.data?.guidelines || "";
        process_id = cod?.data?.id || 0;
      }
 
      Swal.fire({
        title: "",
        html: this.$t("ALERT.ALERTUSERMODULENOTENABLEDBITMASKCREATE", {type: this.$t(`ALERT.${type}`), exist_guidelines: guidelines !== "" ? this.$t("ALERT.ALERTUSERMODULENOTENABLEDBITMASK", {process_id: process_id}) :  ''}),
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },

    alertUserModuleAccessBitmaskUpdate(moduleContentType) {
      const current_process = window.localStorage.getItem("current_process_data");
      const type = moduleContentType?.code || "PROPOSAL";
      let guidelines = ""
      let process_id = 0;

      if( current_process != 'undefined' && current_process !== null ) {
        const cod = JSON.parse(current_process);
        guidelines = cod?.data?.guidelines || "";
        process_id = cod?.data?.id || 0;
      }
 
      Swal.fire({
        title: "",
        html: this.$t("ALERT.ALERTUSERMODULENOTENABLEDBITMASKUPDATE", {type: this.$t(`ALERT.${type}`), exist_guidelines: guidelines !== "" ? this.$t("ALERT.ALERTUSERMODULENOTENABLEDBITMASK", {process_id: process_id}) :  ''}),
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },

    alertUserModuleAccessBitmaskDelete(moduleContentType) {
      const current_process = window.localStorage.getItem("current_process_data");
      const type = moduleContentType?.code || "PROPOSAL";
      let guidelines = ""
      let process_id = 0;

      if( current_process != 'undefined' && current_process !== null ) {
        const cod = JSON.parse(current_process);
        guidelines = cod?.data?.guidelines || "";
        process_id = cod?.data?.id || 0;
      }
 
      Swal.fire({
        title: "",
        html: this.$t("ALERT.ALERTUSERMODULENOTENABLEDBITMASKDELETE", {type: this.$t(`ALERT.${type}`), exist_guidelines: guidelines !== "" ? this.$t("ALERT.ALERTUSERMODULENOTENABLEDBITMASK", {process_id: process_id}) :  ''}),
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },

    alertUserModuleAccessBitmaskVersion(moduleContentType) {
      const current_process = window.localStorage.getItem("current_process_data");
      const type = moduleContentType?.code || "PROPOSAL";
      let guidelines = ""
      let process_id = 0;

      if( current_process != 'undefined' && current_process !== null ) {
        const cod = JSON.parse(current_process);
        guidelines = cod?.data?.guidelines || "";
        process_id = cod?.data?.id || 0;
      }
 
      Swal.fire({
        title: "",
        html: this.$t("ALERT.ALERTUSERMODULENOTENABLEDBITMASKVERSION", {type: this.$t(`ALERT.${type}`), exist_guidelines: guidelines !== "" ? this.$t("ALERT.ALERTUSERMODULENOTENABLEDBITMASK", {process_id: process_id}) :  ''}),
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        customClass: "swal-wide",
      });
    },
  },
};
